// src/components/Dashboard.jsx

import React, { useEffect, useState } from "react";
import TransactionModal from "./TransactionModal";
import TransactionList from "./TransactionList";
import { PlusIcon } from "@heroicons/react/solid";
import VERSION from "../version"; // 導入版本號

function Dashboard({ onLogout }) {
  const authToken = localStorage.getItem("access_token"); // 或 sessionStorage
  const [transactions, setTransactions] = useState([]);
  const [holdings, setHoldings] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [returnRate, setReturnRate] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  const fetchTransactions = async () => {
    if (!authToken) {
      setError("用戶未登入。");
      return;
    }

    try {
      const response = await fetch(
        "https://apimarco.ddns.net/api/transactions",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Fetching transactions, status:", response.status);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "無法獲取交易記錄");
      }
      const data = await response.json();
      console.log("Fetched transactions:", data);
      setTransactions(data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError(error.message || "無法獲取交易記錄。請稍後再試。");
    }
  };

  const calculateStatistics = () => {
    let holdingsCalc = {};
    let sumBuys = 0;
    let sumSells = 0;

    transactions.forEach((tx) => {
      console.log("Transaction:", tx);
      const {
        stock_number,
        stock_name,
        quantity,
        price,
        fee,
        tax,
        type,
        total_cost,
      } = tx;
      if (!holdingsCalc[stock_name]) {
        holdingsCalc[stock_name] = { quantity: 0, cost: 0 };
      }
      if (type === "buy") {
        holdingsCalc[stock_name].quantity += quantity;
        holdingsCalc[stock_name].cost += total_cost; // total_cost 已包含手續費
        sumBuys += total_cost;
      } else if (type === "sell") {
        holdingsCalc[stock_name].quantity -= quantity;
        sumSells += total_cost; // total_cost 已扣除手續費和證交稅
      }
    });
    console.log("Holdings:", holdingsCalc);
    setHoldings(holdingsCalc);
    const netTotalCost = sumBuys - sumSells;
    setTotalCost(netTotalCost);

    // 計算投資報酬率
    const rate = sumBuys !== 0 ? ((sumSells - sumBuys) / sumBuys) * 100 : 0;
    setReturnRate(rate.toFixed(2));
  };

  const handleDelete = async (transactionId) => {
    // 確認用戶是否真的要刪除
    const confirmDelete = window.confirm("您確定要刪除這筆交易嗎？");
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `https://apimarco.ddns.net/api/transactions/${transactionId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(
        `Deleting transaction ${transactionId}, status:`,
        response.status
      );
      if (!response.ok) {
        const data = await response.json();
        console.error("Failed to delete transaction:", data);
        throw new Error(data.msg || "刪除交易失敗");
      }
      // 刪除成功後更新交易列表
      setTransactions(transactions.filter((tx) => tx.id !== transactionId));
      calculateStatistics(); // 重新計算統計數據
      console.log(`Transaction ${transactionId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting transaction:", error);
      setError(error.message || "刪除交易失敗。請稍後再試。");
    }
  };

  const addTransaction = async (transaction) => {
    try {
      const response = await fetch(
        "https://apimarco.ddns.net/api/transactions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(transaction),
        }
      );

      if (!response.ok) {
        console.log("Add transaction failed, status:");
        const data = await response.json();
        console.error("Failed to add transaction:", data);
        throw new Error(data.msg || "新增交易失敗");
      }
      const newTransaction = await response.json();
      console.log("Added transaction:", newTransaction);
      setTransactions([newTransaction, ...transactions]);
      calculateStatistics(); // 重新計算統計數據
      
      setModalOpen(false);
    } catch (error) {
      console.error("Error adding transaction:", error);
      setError(error.message || "新增交易失敗。請稍後再試。");
    }
  };

  return (
    <div className="space-y-6">
      {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* 持股數量 */}
        <div className="bg-card p-6 rounded-lg shadow-lg flex flex-col">
          <h2 className="text-xl font-semibold text-gray-700">持股數量</h2>
          <ul className="mt-4 space-y-2">
            {Object.keys(holdings).map((stock) => (
              <li key={stock} className="flex justify-between items-center">
                <span className="text-gray-600">{stock}</span>
                <span className="text-lg font-bold text-gray-800">
                  {holdings[stock].quantity} 股
                </span>
              </li>
            ))}
          </ul>
        </div>
        {/* 總成本 */}
        <div className="bg-card p-6 rounded-lg shadow-lg flex flex-col">
          <h2 className="text-xl font-semibold text-gray-700">總成本</h2>
          <p className="mt-4 text-2xl font-bold text-primary">
            ${Math.floor(totalCost).toLocaleString()}
          </p>
        </div>
        {/* 投資報酬率 */}
        <div className="bg-card p-6 rounded-lg shadow-lg flex flex-col">
          <h2 className="text-xl font-semibold text-gray-700">投資報酬率</h2>
          <p
            className={`mt-4 text-2xl font-bold ${
              returnRate >= 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {returnRate}%
          </p>
        </div>
      </div>
      {/* 交易記錄列表 */}
      <TransactionList transactions={transactions} onDelete={handleDelete} />
      {/* 新增交易按鈕 */}
      <button
        className="fixed bottom-6 right-6 bg-blue-500 hover:bg-blue-600 text-white p-4 rounded-full shadow-lg flex items-center justify-center transition transform hover:scale-110"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon className="h-6 w-6" />
      </button>
      {/* 新增交易模態視窗 */}
      {modalOpen && (
        <TransactionModal
          onClose={() => setModalOpen()}
          onSave={addTransaction}
        />
      )}
      {/* 顯示版本號 */}
      <div className="mt-6 text-center text-gray-500">版本 {VERSION}</div>
      {/* 登出按鈕 */}
      <button
        onClick={onLogout}
        className="fixed top-2 right-2 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md shadow-lg"
      >
        登出
      </button>
    </div>
  );
}

export default Dashboard;
