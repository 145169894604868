// src/components/NotFound.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import VERSION from '../version'; // 確保版本號已定義

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-red-500">404</h1>
      <p className="mt-2 text-lg text-gray-700">頁面未找到</p>
      <Link to="/" className="mt-4 text-blue-500 hover:underline">
        返回首頁
      </Link>
      {/* 顯示版本號 */}
      <div className="mt-6 text-center text-gray-500">
        版本 {VERSION}
      </div>
    </div>
  );
}

export default NotFound;
