// src/App.jsx

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register'; // 引入 Register 組件
import Dashboard from './components/Dashboard';
import RequestResetPassword from './components/RequestResetPassword';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';

function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('access_token') || '');
  const [error, setError] = useState('');

  const onLogin = async (username, password) => {
    try {
      const response = await fetch('https://apimarco.ddns.net/api/login', { // 替換為您的登入 API 端點
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || '登入失敗');
      }

      const data = await response.json();
      const token = data.access_token; // 根據您的 API 回應結構調整
      setAuthToken(token);
      localStorage.setItem('access_token', token);
      setError('');
    } catch (err) {
      console.error("Login error:", err);
      setError(err.message || '登入失敗。請稍後再試。');
      throw err; // 將錯誤拋出，以便子組件處理
    }
  };

  const onLogout = () => {
    setAuthToken('');
    localStorage.removeItem('access_token');
  };

  return (
    <Router>
      <Routes>
        {/* 根路徑 "/" 的路由 */}
        <Route
          path="/"
          element={
            authToken ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
        {/* 登入頁面 */}
        <Route
          path="/login"
          element={
            authToken ? <Navigate to="/dashboard" /> : <Login onLogin={onLogin} />
          }
        />
        {/* 註冊頁面 */}
        <Route
          path="/register"
          element={
            authToken ? <Navigate to="/dashboard" /> : <Register />
          }
        />
        {/* Dashboard 頁面 */}
        <Route
          path="/dashboard"
          element={
            authToken ? <Dashboard onLogout={onLogout} /> : <Navigate to="/login" />
          }
        />
        {/* 請求重置密碼頁面 */}
        <Route
          path="/request-reset-password"
          element={<RequestResetPassword />}
        />
        {/* 重置密碼頁面 */}
        <Route
          path="/reset-password/:token"
          element={<ResetPassword />}
        />
        {/* 404 頁面 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {error && <div className="fixed bottom-4 right-4 bg-red-500 text-white p-2 rounded">{error}</div>}
    </Router>
  );
}

export default App;
