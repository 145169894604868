// src/components/Login.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // 引入 Link 組件
import VERSION from '../version';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onLogin(username, password);
      // 登入成功後，父組件會自動重定向
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-semibold mb-6 text-center">登入</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">用戶名</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">密碼</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <div>
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 rounded-md hover:bg-secondary transition"
            >
              登入
            </button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <span>還沒有帳號？ </span>
          <Link to="/register" className="text-blue-500 hover:underline">
            註冊
          </Link>
        </div>
        {/* 顯示版本號 */}
        <div className="mt-6 text-center text-gray-500">
          版本 {VERSION}
        </div>
      </div>
    </div>
  );
}

export default Login;
