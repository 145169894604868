// src/components/TransactionList.jsx

import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/solid';

function TransactionList({ transactions, onDelete }) {
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    fetchStocks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStocks = async () => {
    const authToken = localStorage.getItem('access_token');
    try {
      const response = await fetch('https://apimarco.ddns.net/api/stocks', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stocks');
      }
      const data = await response.json();
      setStocks(data);
    } catch (error) {
      console.error("Error fetching stocks:", error);
    }
  };

  const getStockName = (stock_number) => {
    const stock = stocks.find(s => s.stock_number === stock_number);
    return stock ? `${stock.stock_name} (${stock.stock_number})` : stock_number;
  };

  return (
    <div className="bg-card p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">交易記錄</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">股票名稱</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">類型</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">股數</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">價格</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">手續費</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">證交稅</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">總成本</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">操作</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactions.map(tx => (
              <tr key={tx.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{getStockName(tx.stock_number)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 capitalize">{tx.type}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{tx.quantity}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">${tx.price.toFixed(2)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">${tx.fee.toFixed(2)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">${tx.tax.toFixed(2)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">${Math.floor(tx.total_cost).toLocaleString()}</td>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-700">
                  <button
                    onClick={() => onDelete(tx.id)}
                    className="text-red-600 hover:text-red-800 transition"
                  >
                    <TrashIcon className="h-5 w-5 inline" />
                  </button>
                </td>
              </tr>
            ))}
            {transactions.length === 0 && (
              <tr>
                <td colSpan="8" className="px-6 py-4 text-center text-gray-500">
                  尚無交易記錄
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TransactionList;
