// src/components/TransactionModal.jsx

import React, { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/solid';

function TransactionModal({ onClose, onSave }) {
  const authToken = localStorage.getItem('access_token'); // 或 sessionStorage
  const [stocks, setStocks] = useState([]);
  const [stockNumber, setStockNumber] = useState('');
  const [type, setType] = useState('buy');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchStocks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStocks = async () => {
    try {
      const response = await fetch('https://apimarco.ddns.net/api/stocks', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to fetch stocks');
      }
      const data = await response.json();
      setStocks(data);
      if (data.length > 0) {
        setStockNumber(data[0].stock_number); // 預設選擇第一個股票
      }
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setError(error.message || '無法獲取股票列表。請稍後再試。');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const transaction = {
      stock_number: stockNumber, // 使用 stock_number 作為標識
      type: type,
      quantity: parseInt(quantity),
      price: parseFloat(price),
    };
    try {
      await onSave(transaction);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 animate-fadeIn">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">新增交易</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {/* 股票名稱下拉選單 */}
          <div>
            <label className="block text-gray-700">股票名稱</label>
            <select
              value={stockNumber}
              onChange={(e) => setStockNumber(e.target.value)}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              required
            >
              {stocks.map(stock => (
                <option key={stock.stock_number} value={stock.stock_number}>
                  {stock.stock_name} ({stock.stock_number})
                </option>
              ))}
            </select>
          </div>
          {/* 交易類型 */}
          <div>
            <label className="block text-gray-700">交易類型</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              required
            >
              <option value="buy">買入</option>
              <option value="sell">賣出</option>
            </select>
          </div>
          {/* 股數 */}
          <div>
            <label className="block text-gray-700">股數</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              required
              min="1"
            />
          </div>
          {/* 價格 */}
          <div>
            <label className="block text-gray-700">價格</label>
            <input
              type="number"
              step="0.01"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              required
              min="0.01"
            />
          </div>
          {/* 錯誤訊息 */}
          {error && <div className="text-red-500 text-sm">{error}</div>}
          {/* 操作按鈕 */}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition"
            >
              取消
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-secondary transition"
            >
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TransactionModal;
